<template>
  <div>
    <b-container fluid="xl" class="pt-4 pt-lg-5">
      <h1 class="page-title text-center">{{ pageData.title }}</h1>
    </b-container>
      
    <b-container>
      <b-row>
        <b-col md="12 mt-2 mt-lg-3">
          <p style="text-align: center; font-size:11pt;">
            We invite high-caliber individuals who are enthusiastic, innovative and progressive to be part of our establishment<br>
            and to develop a rewarding and satisfying career with us.</p>

          <p style="text-align: center; font-size:11pt;">Below are available vacancies:</p>
        </b-col>
        <!--
        <b-col md="12">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 1 / Job Title: Marketing Executive (Sales & Marketing Department)</span> 
                  <span class="when-opened align-self-center">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed align-self-center">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="accordion-1" role="tabpanel">
                <b-card-body>
                  

                  <p style="text-align:justify"><span style="font-size:11pt"><b>Key Accountabilities:</b></span></p>

                  <ol style="font-size:11pt;">
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Ensure the implementation of the marketing strategies to meet the Company’s Marketing & Sales objectives.</span></li>
                    <li style="text-align:justify; margin-bottom: 11px; margin-left:8px"><span style="font-size:11pt">Assist in strategies resources to develop and plan monthly advertising and promotional program prepare advertising materials and press editorials, analyze and track competitors activities, carry our public relations in order to ensure good publicity and marketability of products of the Company.</span></li>
                    
                  </ol>

                  <p class="mt-5" style="text-align:justify"><span style="font-size:11pt"><b>Key Responsibilities:</b></span></p>

                  <ol style="font-size:11pt;">
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in plan, manager and co-ordinate the marketing, advertising and promotional activities of the Company with the aim of achieving the Company’s Sales and Marketing and profit objectives.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in preparing relevant market survey and trend analysis in order to ensure the Company’s product differentiation, market competitiveness, best pricing strategies, appropriate marketing and sales strategies and formulation of new & effective marketing plans.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in developing advertising materials for new products launches.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in planning, co-ordinate and implement the rebranding exercise of the Company. To liaise and work closely with the rebranding consultants on the rebranding exercise of the Company in order to achieve the objectives of the Company relating to the rebranding exercise.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in developing new possible marketing channels including traditional/digital media, exhibition/roadshows, venue etc./span></span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in managing the Company’s social media channels in respect of brand, product and sales activities’ awareness.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To involve in product planning in new developments and products.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in the overall leasing/rental activity of the Company’s commercial properties.To assist in monitoring rental/lease income budgets, market research, latest marketing trends/intelligent liaising with solicitors on tenancy/lease term and conditions, handling tenancy/lease administration and management and ensuring strong relationships are maintain with the tenants/lessees of the Company.</span></li>
                    <li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To assist in providing periodically report to Management, General Manger and Director(s) of the Company on the marketing status, marketing performance and competitor’s activities (if required).</span></li>
					<li style="text-align: justify; margin-left: 8px;"><span style="font-size:11pt">To attend trade meetings and industry conventions.</span></li>
                    <li style="text-align: justify; margin-bottom: 11px; margin-left: 8px;"><span style="font-size:11pt">Undertake any other duties that may be assigned from time to time by superior/General Manager.</span></li>
                  </ol>
				  
				  <p style="text-align:justify"><strong><span style="font-size:11pt">Qualification and Requirements:</span></strong></p>
				  <ol style="font-size:11pt;">
                  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Degree/Diploma in Marketing/Business Administration Management or equivalent</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Preferable with minimum 2 years of experience in digital marketing</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Experienced in handling sales campaigns/events/roadshows will be an added advantage</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Positive attitude, detail-oriented, resourceful and possess good communication & interpersonal skills</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Able to co-ordinate assignments involving various parties</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Team player and able to work on weekends</span></li>
                  <li style="text-align:justify; margin-bottom: 11px; margin-left:8px"><span style="font-size:11pt">Possess own transport and valid driving license</span></li>
                    
				  </ol>
				  
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
		
        <b-col md="12" class="my-3">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 2 / Job Title: Sales Executive (Sales & Marketing Department)</span> 
                  <span class="when-opened">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="accordion-3" role="tabpanel">
                <b-card-body>

                  <p style="text-align:justify"><span style="font-size:11pt"><b>Key Accountabilities:</b></span></p>
				  
				  <ol style="font-size:11pt;">
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Ensure the sale implementation to achieve sales target.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Ensure all products are carried out accordance to the marketing plan.</span></li>
					<li style="text-align: justify; margin-bottom: 11px; margin-left: 8px;"><span style="font-size:11pt">Preparation of market research and analysis report.</span></li>
                    
                  </ol>

                  
                  <p class="mt-5" style="text-align:justify"><span style="font-size:11pt"><b>Key Responsibilities:</b></span></p>

                  <ol style="font-size:11pt;">
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To attend to phone, website, email enquiries and walk in customer & follow up with potential prospects to ensure monthly sales target is achieved.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To attend collection, correspondence and follow up with purchasers/bankers/solicitors.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To handle loan arrangement/ documentation for purchasers.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To ensure prompt signing of Sales & Purchase Agreement and prompt payment.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To prepare, process and check documents solicitors and bankers.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To provide before and after sales customer service.</span></li>
                    <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To assist in preparing in promotion and marketing of the property via exhibition, roadshows, launching & etc.</span></li>
					<li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To update sales status on MHub system.</span></li>
					<li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">To prepare NAPIC report.</span></li>
					<li style="text-align:justify; margin-left: 8px;"><span style="font-size:11pt">To communicating with Cawangan Perumahan Johor.</span></li>
                  </ol>

                  <p style="text-align:justify"><strong><span style="font-size:11pt">Qualification and Requirements:</span></strong></p>
				  <ol style="font-size:11pt;">
                  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Degree/Diploma in Business Administration Management/Marketing or equivalent</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Preferable with minimum 2 years of relevant experience in property/real estate industry</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Positive attitude, detail-oriented and possess good communication & interpersonal skills </span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Able to co-ordinate assignments involving various parties</span></li>
				  <li style="text-align:justify; margin-left:8px"><span style="font-size:11pt">Team player and able to work on weekends</span></li>
				  <li style="text-align:justify; margin-bottom: 11px; margin-left:8px"><span style="font-size:11pt">Possess own transport and valid driving license</span></li>
                    
				  </ol>

                 

                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
		-->
		<!--
		 <b-col md="12" class="my-3">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-5 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 3 / Job Title: Contract Executive / Senior Contract Executive (Contract Division)</span> 
                  <span class="when-opened">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="accordion-5" role="tabpanel">
                <b-card-body>
				
				  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Key Accountabilities</strong></span></p>
				  <ol>
                    <li><span style="font-size:11pt">Responsible for contract procurement works and ensures the pre and post contract works to be executed according to Contract Division’s standard procedures and requirements.</span></li>
                   
                    
                  </ol>
				  <p>&nbsp;</p>

                  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Key Responsibilities</strong></span></p>
				  <span style="margin-left:7px;font-size:11pt"><u>Pre-Contract</u></span>
				  
				  <ol>
                    <li><span style="font-size:11pt">Establish preliminary project construction cost plan.</span></li>
                    <li><span style="font-size:11pt">Review cost plan, coordinate with Consultants to achieve cost effective design.</span></li>
                    <li><span style="font-size:11pt">Preparation of tender/quotation documents; including full sets of Bills of Quantities and compile the entire necessary appendixes to satisfy for the tender/quotation calling purpose.</span></li>
					<li><span style="font-size:11pt">Preparation tender report, tender clarification, post tender revision document and contract award recommendation papers.</span></li>
					<li><span style="font-size:11pt">Compilation of contract documents.</span></li>
				  </ol>
				  <br />
				  <span style="margin-left:7px;font-size:11pt"><u>Post Contract</u></span>
				  <ol>
                    <li><span style="font-size:11pt">Prepare and assess progress claim, contractual claims and final account i.e. perform joint site valuation, prepare FRV and review VO claims.</span></li>
                    <li><span style="font-size:11pt">Prepare and evaluate Consultants’ claims.</span></li>
                    <li><span style="font-size:11pt">Monitor contractual status i.e. contract period, EOT, CPC, DLP, CMGD etc.</span></li>
					<li><span style="font-size:11pt">Coordinate with Consultants in regards of contractual matters.</span></li>
					<li><span style="font-size:11pt">Preparation of Elemental Cost Analysis.</span></li>
				  </ol>
				  <br />
				  <span style="margin-left:7px;font-size:11pt"><u>Others</u></span>
				  <ol>
                    <li><span style="font-size:11pt">Collate and compile cost data and report.</span></li>
                    <li><span style="font-size:11pt">Review and update contractor’s approval list.</span></li>
                    <li><span style="font-size:11pt">Undertake any other duties as directed by HOD.</span></li>
				  </ol>
                  <p>&nbsp;</p>

                  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Qualification and experience</strong></span></p>

                  <ol>
                    <li><span style="font-size:11pt">Degree in Quantity Surveying or equivalent</span></li>
                    <li><span style="font-size:11pt">Minimum 3 years of working experience in contract administration</span></li>
                    <li><span style="font-size:11pt">Possess strong planning, communication, interpersonal, organization and negotiation skills  </span></li>
					<li><span style="font-size:11pt">Proactive, dynamic and good team player</span></li>
					<li><span style="font-size:11pt">Able to work independently to maintain high standards and accuracy</span></li>
					                    
                  </ol>
				</b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
		
		<b-col md="12" class="my-3">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-6 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 1 / Job Title: Executive / Senior Executive(Township Management Division)</span> 
                  <span class="when-opened">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-6" accordion="accordion-6" role="tabpanel">
                <b-card-body>
				
				  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Key Responsibilities</strong></span></p>
				  <ol>
                    <li><span style="font-size:11pt">To comply and achieve total customer satisfaction in handover procedure for all properties.</span></li>
                    <li><span style="font-size:11pt">Attend to customer joint inspection during vacant possession (VP) and to update customers on status of rectification work. </span></li>
                    <li><span style="font-size:11pt">Attend to complaints and ensure that follow-up actions are taken to address the complaints within the time frame.</span></li>
					<li><span style="font-size:11pt">Ensure customer satisfaction on completed products after handover of the property to the purchaser by deliver high quality of customer services.</span></li>
					<li><span style="font-size:11pt">Supervise, monitor and follow-up with the contractors and consultants on the defect. rectification works on site and ensure the quality of rectification works is carried out. </span></li>
					<li><span style="font-size:11pt">Update and maintain good recoding and tracking system to ensure complaints are closed within stipulated time frame.</span></li>
					<li><span style="font-size:11pt">Analyse complaint data and update relevant parties regarding the quality of performance.</span></li>
					<li><span style="font-size:11pt">To prepare QA/QC & defect rectification report.</span></li>
					<li><span style="font-size:11pt">Assist in customer service programmes, such as focus group survey, community activities and residents meeting.</span></li>
					<li><span style="font-size:11pt">Handling the general repair and maintenance of the Corporate Office/Gated Community.</span></li>
					<li><span style="font-size:11pt">Handling the gated community and stratified management including security guard service, landscaping, barrier gate system etc. </span></li>
                  </ol>
				  <p>&nbsp;</p>

                  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Qualification and Requirement</strong></span></p>
				  
                  <ol>
                    <li><span style="font-size:11pt">Diploma/Degree in Building, Property Management, Construction Management or Civil Engineering and Built Environment related degree.</span></li>
                    <li><span style="font-size:11pt">Minimum 2 years of site experience in related construction work and 1 year experience in handling customer feedback.</span></li>
                    <li><span style="font-size:11pt">Experience in handling customers angers and frustration, and able to co-ordinate assignment involving many parties.</span></li>
					<li><span style="font-size:11pt">Customer oriented and strong relationship building abilities.</span></li>
					<li><span style="font-size:11pt">Prior experience in property development industry related to product inspection and knowledge in product quality is an added advantage. </span></li>
					<li><span style="font-size:11pt">Able to work as team.</span></li>
					                    
                  </ol>
				</b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
		-->
		<b-col md="12" class="my-3">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 card-career">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.accordion-7 variant="secondary" size="lg" class="rounded-0 text-left btn-yellow btn-position-title d-flex justify-content-between px-4">
                  <span>Position 1 / Job Title: Project Manager (Project Department)</span> 
                  <span class="when-opened">
                    <b-icon icon="chevron-compact-up"></b-icon>
                  </span>
                  <span class="when-closed">
                    <b-icon icon="chevron-compact-down"></b-icon>
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-7" accordion="accordion-7" role="tabpanel">
                <b-card-body>
				
				  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Key Responsibilities</strong></span></p>
				  <ol>
                    <li><span style="font-size:11pt">To ensure timely availability of products for sales launching purpose.</span></li>
                    <li><span style="font-size:11pt">To ensure the completed products are delivered within time schedule, quality standard and budgeted cost. </span></li>
                    <li><span style="font-size:11pt">To ensure Certificate of Completion and Compliance (CCC) for completed parcels are obtained within the specified timeline.</span></li>
					
                  </ol>
				  <p>&nbsp;</p>
				  
				  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Key Responsibilities</strong></span></p>
				  
				  <ol>
                    <li><span style="font-size:11pt">To effectively manage the construction of the designated projects through the overall planning, control, leadership & co-ordination of the project implementation department & trade contractors in order that the project will be completed on time, within budgeted cost and to the required quality & safety standards.</span></li>
                    <li><span style="font-size:11pt">Review contract/tender documents/drawings and assist in tender preparation.</span></li>
                    <li><span style="font-size:11pt">Co-ordinate and lead the entire project team, contractors, subcontractors, architects, consultants and other authorities to ensure high quality products is complete within specific time frame.</span></li>
					<li><span style="font-size:11pt">Work closely with all relevant department in ensuring successful implementation.</span></li>
					<li><span style="font-size:11pt">Monitor work progress, quality of works, compliance to contract specifications, costs and the implementation of all relevant policies, activities, procedures and instructions.</span></li>
					<li><span style="font-size:11pt">Proactive in planning for site meetings and sitewalks.</span></li>
					<li><span style="font-size:11pt">Manage the construction process by prioritising, answering questions, managing deadlines and resolving larger issues associated with putting together a bid.</span></li>
					<li><span style="font-size:11pt">Be conversant with the most recent construction methods, materials, technologies and procedures.</span></li>
					<li><span style="font-size:11pt">Coordinate and review drawings and specifications to identify discrepancies and resolve technical issues.</span></li>
					<li><span style="font-size:11pt">Liaise with consultants and other project stakeholders on day-to-day project operations</span></li>
					<li><span style="font-size:11pt">Identify all remedial and outstanding works that must be completed.</span></li>
					<li><span style="font-size:11pt">Undertake to prepare pre-development feasibility studies and recommendation to the Management prior to commencement of project.</span></li>
					<li><span style="font-size:11pt">Compilation of case studies and best practices for use in future projects. </span></li>
					<li><span style="font-size:11pt">Keep track of the progress billing stage completion and timely issuance of the consultants certificates. </span></li>
					<li><span style="font-size:11pt">Attend to Authorities inspection and meetings when required. </span></li>
					<li><span style="font-size:11pt">To ensure CCC for completed parcels are obtained within the specified timeline. </span></li>
					<li><span style="font-size:11pt">To set up and implement the product QA/QC procedures in accordance with company requirements & guidelines.</span></li>
					<li><span style="font-size:11pt">To verify and certify consultants and contractors progress claim, site staff monthly claim and over-time claim.</span></li>
					<li><span style="font-size:11pt">To evaluate and review the performance of the consultants and contractors pertinent to their respective areas of works and responsibilities. </span></li>
					<li><span style="font-size:11pt">To be responsible for the implementation of the construction works commencing from the tender award to practical completion of projects and vacant possession. </span></li>
					<li><span style="font-size:11pt">Undertake any duties assigned by the Management from time to time.</span></li>
                  </ol>

                  <p style="margin-left:7px; margin-right:-1px"><span style="font-size:11pt"><strong>Qualification and Requirement</strong></span></p>
				  
                  <ol>
                    <li><span style="font-size:11pt">Degree in Civil and Structural Engineering or an equivalent </span></li>
                    <li><span style="font-size:11pt">At least 10 years of experience in developing and managing development and construction projects are required or engineer from consultant firm.</span></li>
                    <li><span style="font-size:11pt">Experience with project plan development and implementation as well as the operations and processes in property development industry</span></li>
					<li><span style="font-size:11pt">Excellent analytical and construction skills, self-motivated and capable of leading a project team</span></li>
					<li><span style="font-size:11pt">Excellent interpersonal and communication skills to promote effective communication, feedback and motivation among members of the project team </span></li>
					<li><span style="font-size:11pt">Proficient in written and spoken English</span></li>
					                    
                  </ol>
				</b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
		
        <b-col md="12 my-5">
          <p style="text-align: center;">
            <span style="font-size:11pt"><span>Please send your CV with present &amp; expected salary and&nbsp;&nbsp;latest photograph to the address below :-</span><br>
            <span style="font-size:11pt"><span class="text-yellow"><b>The Human Resource and Administration Manager<br>
            Wisma Impian Emas, No.55, Jalan Impian Emas 5/1, Taman Impian Emas,<br>
            81300 Skudai, Johor</b></span>
          </span></span></p>

          <p style="text-align: center;"><span style="font-size:11pt"><span><span style="color: black;">OR</span></span></span></p>

          <p style="text-align: center;"><span style="font-size:11pt"><span>by e-mailing your application to : <br><span class="text-yellow">hr@impianemas.my</span></span></span></p>

          <p style="text-align: center;"><span style="font-size:11pt">(Only short listed candidates will be notified)</span></p>
        </b-col>

      </b-row>

    </b-container>
  </div>
</template>

<script>

// @ is an alias to /src
export default {
  name: 'Enquiry',
  props: {
    pageData: Object
  },
  components: {
  },
  metaInfo() {
        return {
            title: "Impian Emas - Career Opportunities",
            meta: [
                { name: 'description', content: "Impian Emas - Career Opportunities"},
                { property: 'og:title', content: 'Impian Emas - Career Opportunities'},
                { property: 'og:site_name', content: 'Impian Emas'},
                { property: 'og:description', content: 'Impian Emas - Career Opportunities'},
                { property: 'og:type', content: 'Career Opportunities'},
                    
            ]
        }
    },
}
</script>


<style scoped>
  .page-title {
    color: #e8a713;
  }

  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }

  .btn-position-title {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .card-career {
    border-radius: 12px;
  }

  @media only screen and (max-width: 768px) {
    .btn-position-title {
      font-size: 14pt;
    }
  }
</style>