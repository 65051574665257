import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'

import VueEasyLightbox from 'vue-easy-lightbox'

import VueDragscroll from 'vue-dragscroll'
import VueGtag from "vue-gtag"
import VueGtm from '@gtm-support/vue2-gtm'

// import jquery
import JQuery from 'jquery'
window.$ = JQuery

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import LayoutDefault from './layouts/LayoutDefault'
import LayoutBlank from './layouts/LayoutBlank'

Vue.config.productionTip = false

// global variable
Vue.prototype.$axios = axios

import {STO_BASE_URL,  STO_API_SECRET}  from '../public/configuration.js'
Vue.prototype.$apiUrl = STO_BASE_URL;
Vue.prototype.$apiToken = STO_API_SECRET;

Vue.prototype.$globalData = {};



Vue.component('layout-default', LayoutDefault)
Vue.component('layout-blank', LayoutBlank)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueLazyload)

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// Configuration VueAnalytics
//Vue.use(VueGtag, {
//  config: { id:'G-1NV6M5YHPE'},
//  router
//})


Vue.use(VueGtag, {
  config: { id: "G-1NV6M5YHPE" }
}, router);

Vue.use(VueGtm, {
  id: 'GTM-M4WCTCNJ',
  defer: false,
  enabled: true,
  debug: true,
  vueRouter: router,
  loadScript: true
}) 


// or with options
const loadimage = require('./assets/images/project-image-placeholder.jpeg')
const errorimage = require('./assets/images/loading.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

library.add(fas)
library.add(far)
library.add(fab)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueEasyLightbox)

Vue.use(VueDragscroll)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
