<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
  import VideoIntro from '@/components/VideoIntro.vue'

  const LayoutDefault = 'default'

  export default {
    name: 'App',
    computed: {
      layout() {
        return 'layout-' + (this.$route.meta.layout || LayoutDefault)
      }
    },
    components: {
      VideoIntro
    },
    data () {
      return {
        siteInfo: []
      }
    },
    created () {
      this.$axios
        .get(this.$apiUrl+'/site-info?token='+this.$apiToken)
        .then(response => {
          this.siteInfo = response.data;
          // console.log(this.siteInfo);
        }).catch(error => {
          console.log('There was an error:' + error.response)
        })
    },
	metaInfo() {
        return { 
            title: "Impian Emas - The Golden District",
            meta: [
                { name: 'description', content:  'Taman Impian Emas, golden district properties'},
                { property: 'og:title', content: "Impian Emas - The Golden District"},
                { property: 'og:site_name', content: 'Impian Emas'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
  }
</script>

<style>

  @font-face {
    font-family: 'Trebuchet MS';
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/trebuc.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Trebuchet MS Italic';
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/Trebuchet-MS-Italic.woff') format('woff');
  }

  @font-face {
    font-family: 'Futura';
    font-weight: normal;
    src: url('/assets/fonts/Futura.ttc') format('TTC');
  }

  @font-face {
    font-family: 'Futura Heavy';
    font-weight: bold;
    src: url('/assets/fonts/Futura Heavy.ttf') format('TTF');
  }

  html, body {
    height: 100%!important;
    font-family: 'Trebuchet MS', sans-serif;
  }

  body {
    display: flex;
    flex-direction: column;
    height: 100%;   
  }

  img {
    -webkit-backface-visibility: hidden; 
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
  }
    
  div::-webkit-scrollbar-track
  {
      border: 5px solid white;
      /*border color does not support transparent on scrollbar*/
      border-color: transparent;
      background-color: #ffffff00;
  }

  div::-webkit-scrollbar
  {
      width: 8px;
      background-color: #ffffff00;
  }

  div::-webkit-scrollbar-thumb
  {
      background-color: #9c9c9c;
      border-radius: 10px;
  }

  #app {
    font-family: 'Trebuchet MS', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* text-align: center; */
    /* color: #2c3e50; */
  }

  p {
    font-family: 'Trebuchet MS', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    background: #FDFDFD;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1440px !important;
    }
  }

  .bg-dark {
    background: #333132;
  }

  .bg-yellow {
    background: #e8a713;
  }

  .gallery-img {
    object-fit: cover;
    width: 100%;
    height: 250px !important;
  }

  .icon-yellow-fb {
    background-image: url('assets/images/icon-yellow-fb.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }

  .icon-yellow-instagram {
    background-image: url('assets/images/icon-yellow-instagram.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }

  .icon-yellow-whatsapp {
    background-image: url('assets/images/icon-yellow-whatsapp.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }  

  .icon-yellow-360 {
    background-image: url('assets/images/icon-yellow-360.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }

  .text-yellow {
    color: #e8a713;
  }

  .btn {
    border-radius: 12px !important;
  }

  .btn-yellow {
      color: #fff !important;
      background-color: #e8a713 !important;
      border-color: #e8a713 !important;
  }

  .modal-dialog {
    /* width: 100%; */
    /* height: 100%; */
    padding: 0;
  }

  .modal-content {
    height: 100%;
    border-radius: 0;
  }

  #article-view-modal .modal-header {
    border-bottom: none !important;
  }

  #article-view-modal .modal-content {
    border-radius: 12px;
  }

 .modal-header .close {
    padding: 0rem 1rem !important;
    margin: -1rem -1rem -1rem auto;
    font-size: 42px;
    color: #e8a713;
  }

  .modal .close {
    opacity: 1;
  }

  .modal .close:not(:disabled):not(.disabled):hover, .modal .close:not(:disabled):not(.disabled):focus {
    opacity: .75;
  }  

  .modal .close:hover {
    color: #da9e12;
  }

  /* Pagination */
  .page-link {
    background: #ffffff00 !important;
    border: none !important;
    color: #e8a713 !important;
    font-weight: bold;
    padding: 3px 3px 3px 3px !important;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.15em;
  }

.page-item.active .page-link {
    z-index: 3;
    color: #e8a713 !important;
    background: #ffffff00 !important;
    background-color: #ffffff00 !important;
    border-color: #ffffff00 !important;
    border-bottom: 2px solid #e8a713 !important;
}

.page-item.disabled .page-link {
    color: #e8a713a6 !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-link:hover {
    z-index: 2;
    text-decoration: none;
    background-color: #ffffff !important;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    box-shadow: none !important;
}

  #main-nav .navbar-toggler{
    border: none;
    color:#e8a713;
    font-size: 2.55em;
  }

  #main-nav .navbar-toggler svg path {
    stroke: #e8a713;
    stroke-width: 0.5px;
  }


  @media only screen and (max-width: 768px) {
    #main-nav {
      padding: 10px 25px 10px 25px;
    }

    .modal-header .close {
        padding: 0rem 1rem !important;
        margin: -1rem -1rem -1rem auto;
        font-size: 2.3em;
        color: #e8a713;
        font-weight: normal;
    }

  }


  @media only screen and (max-width: 768px) {
    .page-title {
      color: #e8a713;
      font-size: 1.75em;
    }

  }



</style>
