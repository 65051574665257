<template>
  <div>
    <b-container fluid="xl" class="pt-4 pt-lg-5">
      <h1 class="page-title text-center">Facilities & Amenities</h1>
    </b-container>
      
    <b-container class="py-3 mb-5 px-0">
      <div class="fa-3d">
        <swiper class="swiper fa-swiper" :options="swiperOption">
          <swiper-slide class="slide-1"><div class="slide-title">Impian Emas Golf Course</div></swiper-slide>
          <swiper-slide class="slide-2"><div class="slide-title">Southern University College</div></swiper-slide>
          <swiper-slide class="slide-3"><div class="slide-title">Thai Hong Primary School</div></swiper-slide>
          <swiper-slide class="slide-4"><div class="slide-title">Recreation Park @ Zone 2</div></swiper-slide>
          <swiper-slide class="slide-5"><div class="slide-title">Recreation Park @ Zone 2</div></swiper-slide>
          <!-- <swiper-slide class="slide-6"><div class="slide-title">Recreation Park @ Zone 10</div></swiper-slide>
          <swiper-slide class="slide-7"><div class="slide-title">Recreation Park @ Zone 10</div></swiper-slide>
          <swiper-slide class="slide-8"><div class="slide-title">Recreation Park @ Zone 10</div></swiper-slide> -->
          <swiper-slide class="slide-9"><div class="slide-title">Impian Sport Center</div></swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <div class="swiper-pagination-wrapper d-flex justify-content-center">
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
      </div>

    </b-container>



  </div>
</template>

<script>

// @ is an alias to /src
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Enquiry',
  props: {
    pageData: Object
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        slidesPerView: 2,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows : true
        },
        // coverflowEffect: {
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows : true
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    }
  },
  metaInfo() {
        return {
            title: "Impian Emas - Facilities and amenities",
            meta: [
                { name: 'description', content: "Impian Emas - Facilities and amenities"},
                { property: 'og:title', content: 'Impian Emas - Facilities and amenities'},
                { property: 'og:site_name', content: 'Impian Emas'},
                { property: 'og:description', content: 'Impian Emas - Facilities and amenities'},
                { property: 'og:type', content: 'Facilities and amenities'},
                    
            ]
        }
    },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>


<style>
  .page-title {
    color: #e8a713;
  }

.fa-3d {
	 width: 100%;
	 height: 700px;
	 padding-top: 50px;
	 padding-bottom: 50px;
}
 .fa-swiper {
	 height: 100%;
	 width: 100%;
}
.fa-swiper .swiper-slide {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 width: 600px;
	 height: 600px;
	 text-align: center;
	 font-weight: bold;
	 font-size: 40px;
	 background-color: #2c8dfb;
	 background-position: center;
	 background-size: cover;
	 color: #fff;
  justify-content: center;
  align-items: flex-end;
}

.fa-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
	 background-color: #fff;
}
 
.fa-swiper .swiper-button-prev, .fa-swiper .swiper-button-next {
  color: #e8a713;
  font-size: 14px !important;
  padding: 5px;
}

.fa-swiper .swiper-button-prev:after, .fa-swiper .swiper-container-rtl .swiper-button-next:after {
    content: 'prev';    
    font-size: 18px;
    font-weight: bold;
    padding: 12px 16px;
    border: 2px solid;
    border-radius: 52px;
    background:rgba(0, 0, 0, 0.432);
}

.fa-swiper .swiper-button-next:after, .fa-swiper .swiper-container-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px;
    font-weight: bold;
    padding: 12px 16px;
    border: 2px solid;
    border-radius: 52px;
    background:rgba(0, 0, 0, 0.432);
}

.swiper-pagination-wrapper {
  margin-top: 20px;
}

.swiper-pagination-bullet-active {
  background-color: #e8a713 !important;
}

.swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  border: 2px solid #e8a713 !important;
  background-color: #fff;
  opacity: 1;
  margin-left: 5px;
  margin-right: 5px;
}

/* .swiper {
	 height: 360px;
} */
 .fa-swiper .swiper-slide {    
   background-position: center center !important;
    background-size: cover !important;
}
 .fa-swiper .swiper-slide.slide-1 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-golf-course.jpg');
}
 .fa-swiper .swiper-slide.slide-2 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-Southern-University.jpg');
}
 .fa-swiper .swiper-slide.slide-3 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-thaihong.jpg');
}
 .fa-swiper .swiper-slide.slide-4 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-park.jpg');
}
 .fa-swiper .swiper-slide.slide-5 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-park2.jpg');
}
 .fa-swiper .swiper-slide.slide-6 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-park-@-Zone-10_1.jpg');
}
 .fa-swiper .swiper-slide.slide-7 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-park-@-Zone-10_2.jpg');
}
 .fa-swiper .swiper-slide.slide-8 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-park-@-Zone-10_3.jpg');
}
 .fa-swiper .swiper-slide.slide-9 {
	 background-image: url('https://staging.nixsercloud.com/uploads/companies/40/image/facilities/fac-impian-Sport.jpg');
}

.fa-swiper .slide-title {
  font-size: 16pt;
  margin-bottom: 32px;
}

@media only screen and (max-width: 768px) {
  .fa-3d {
    width: 100%;
    height: 350px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
   .fa-swiper .swiper-slide {    
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    transform: none !important;
    height: 350px;
  }
}


</style>