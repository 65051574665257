<template>
  <div>
    <b-navbar toggleable="lg" variant="light" type="light" id="main-nav" class="bg-white shadow-sm">
      <!-- <b-navbar-brand href="#">Nixser Cloud CMS</b-navbar-brand> -->
      <b-navbar-brand v-bind:to="'/'">  
        <img :src="header.brand_image_url" alt="" class="footer-logo" />
      </b-navbar-brand>

      <b-navbar-toggle target="" @click.native="toggleSidebar($event)">
        <!-- <b-icon icon="list"></b-icon> -->
        <font-awesome-icon icon="fa-solid fa-bars" />
      </b-navbar-toggle>

      <!-- <b-navbar-toggle target="nav-collapse">
        <b-icon icon="list"></b-icon>
      </b-navbar-toggle> -->

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li v-for="link in header.links" :key="link.id" v-bind:class="[link.level_two_links.length > 0 ? 'dropdown' : '' ]" class="nav-item">
            <router-link v-bind:to="link.level_two_links.length > 0 ? '' : link.url" class="nav-link" v-bind:class="[link.level_two_links.length > 0 ? 'dropdown-toggle' : '' ]"  :data-toggle="link.level_two_links.length > 0 ? 'dropdown' : ''">
              <span>{{ link.name }}</span>  <!-- level 1 -->
            </router-link>
            
            <ul v-if="link.level_two_links.length > 0" class="dropdown-menu"> 
              <li v-for="childLink in link.level_two_links" :key="childLink.id" v-bind:class="[childLink.level_three_links.length > 0 ? 'dropdown-submenu' : 'dropdown-submenu' ]">

                <a v-if="childLink.link_type == 'url'" :href="childLink.url" v-bind:class="[childLink.level_three_links.length > 0 ? 'dropdown-item dropdown-toggle' : 'dropdown-item' ]" :data-toggle="childLink.level_three_links.length > 0 ? 'dropdown' : ''" :target="childLink.target">{{ childLink.name }}</a>

                <router-link v-else v-bind:to="childLink.level_three_links.length > 0 ? '' : childLink.url" v-bind:class="[childLink.level_three_links.length > 0 ? 'dropdown-item dropdown-toggle' : 'dropdown-item' ]" :data-toggle="childLink.level_three_links.length > 0 ? 'dropdown' : ''">
                  {{ childLink.name }} <!-- level 2 -->
                </router-link>

                <ul v-if="childLink.level_three_links.length > 0" class="dropdown-menu"> 

                  <li v-for="childLink1 in childLink.level_three_links" :key="childLink1.id" v-bind:class="[childLink1.level_four_links.length > 0 ? 'dropdown-submenu' : '' ]">
					
					<a v-if="childLink1.link_type == 'url'" :href="childLink1.url" v-bind:class="[childLink1.level_four_links.length > 0 ? 'dropdown-item dropdown-toggle' : 'dropdown-item' ]" :data-toggle="childLink1.level_four_links.length > 0 ? 'dropdown' : ''" :target="childLink1.target">{{ childLink1.name }}</a>

                    <router-link v-else v-bind:to="childLink1.level_four_links.length > 0 ? '' : childLink1.url" v-bind:class="[childLink1.level_four_links.length > 0 ? 'dropdown-item dropdown-toggle' : 'dropdown-item' ]" :data-toggle="childLink1.level_four_links.length > 0 ? 'dropdown' : ''">
                      {{ childLink1.name }} <!-- level 3 -->
                    </router-link>

                    <ul v-if="childLink1.level_four_links.length > 0" class="dropdown-menu"> 
                        <li v-for="childLink2 in childLink1.level_four_links" :key="childLink2.id">
						<a v-if="childLink2.link_type == 'url'" :href="childLink2.url" v-bind:class="[childLink2.level_five_links.length > 0 ? 'dropdown-item dropdown-toggle' : 'dropdown-item' ]" :data-toggle="childLink2.level_five_links.length > 0 ? 'dropdown' : ''" :target="childLink2.target">{{ childLink2.name }}</a>
						
                          <router-link v-else v-bind:to="childLink2.url" class="dropdown-item">
                            {{ childLink2.name }} <!-- level 4 -->
                          </router-link>
                        </li>
                      </ul>
                  </li>

                </ul>

              </li>  
            </ul>
          </li>
          <b-nav-item-dropdown right class="dropdown-lang d-none">
            <template slot="button-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
              </svg>
            </template>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">MY</b-dropdown-item>
            <b-dropdown-item href="#">ZH</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-sidebar ref="mobileSidebar" id="mobile-sidebar" class="mobile-sidebar" aria-labelledby="mobile-sidebar-title" no-header shadow>
      <template #default="{ hide }">
        <div class="p-0">

          <div class="mobile-header">
            <b-navbar-brand v-bind:to="'/'">
              <img :src="header.brand_image_url" alt="" class="footer-logo" />
            </b-navbar-brand>

            <b-navbar-toggle target="" @click.native="toggleSidebar($event)">
              <font-awesome-icon icon="fa-solid fa-bars" />
            </b-navbar-toggle>
          </div>

          <div class="px-2 my-3">
            <nav class="">
              <b-nav vertical class="nav-mobile">
                <li 
                  v-for="link in header.links" 
                  :key="link.id"   
                  class="nav-item"
                >
                  <router-link 
                    :to="link.level_two_links.length > 0 ? '' : link.url" 
                    @click.native="subMenuLevel2($event, link)"
                    class="nav-link" 
                   >
                    <span>{{ link.name }}</span>
                    <font-awesome-icon icon="fa-solid fa-chevron-right" class="nav-icon ml-2" style="stroke:#CF8C29; stroke-width: 35px; padding-top: 1px;" v-if="link.level_two_links.length > 0"/>  
                  </router-link>
                </li>
                <!-- <b-nav-item 
                  v-for="link in header.links" 
                  :key="link.id" 
                  v-bind:to="link.level_two_links.length > 0 ? '' : link.url" 
                  @click.native="routeRedirect"
                  >
                   
                  <span>{{ link.name }}</span>
                  <font-awesome-icon icon="fa-solid fa-chevron-right" class="nav-icon" style="stroke:#CF8C29; stroke-width: 35px; padding-top: 1px;" v-if="link.level_two_links.length > 0"/>
                </b-nav-item> -->
                <!-- <b-nav-item active @click="hide">Active</b-nav-item>
                <b-nav-item href="#link-1" @click="hide">Link</b-nav-item>
                <b-nav-item href="#link-2" @click="hide">Another Link</b-nav-item> -->
              </b-nav>
            </nav>  
          </div>
          
        </div>
      </template>
    </b-sidebar>

    <b-sidebar ref="mobileSidebar2" id="mobile-sidebar-level-2" class="mobile-sidebar" aria-labelledby="mobile-sidebar-title" no-header shadow>
      <template #default="{ hide }">
        <div class="p-0">

          <div class="mobile-header">
            <b-navbar-brand v-bind:to="'/'">
              <img :src="header.brand_image_url" alt="" class="footer-logo" />
            </b-navbar-brand>

            <b-navbar-toggle target="" @click.native="toggleSidebar($event)">
              <font-awesome-icon icon="fa-solid fa-bars" />
            </b-navbar-toggle>
          </div>

          <div class="px-2 my-3">
          <button class="btn btn-warning btn-back-sidebar" @click="hide">
              <font-awesome-icon icon="fa-solid fa-chevron-left" style="color: #fff;"/>  
          </button>
            <nav class="">
              <b-nav vertical class="nav-mobile">
                <li 
                  v-for="link in levelTwoLinks" 
                  :key="link.id"   
                  class="nav-item"
                >
				<a v-if="link.link_type == 'url'" :href="link.url" :target="link.target" class="nav-link">{{ link.name }}</a>
                  <router-link v-else
                    :to="link.level_three_links.length > 0 ? '' : link.url" 
                    @click.native="subMenuLevel3($event, link)"
                    class="nav-link" 
                   >
                    <span>{{ link.name }}</span>
                    <font-awesome-icon icon="fa-solid fa-chevron-right" class="nav-icon ml-2" style="stroke:#CF8C29; stroke-width: 35px; padding-top: 1px;" v-if="link.level_three_links.length > 0"/>  
                  </router-link>
                </li>
              </b-nav>
            </nav>  
          </div>
          
        </div>
      </template>
    </b-sidebar>

    <b-sidebar ref="mobileSidebar3" id="mobile-sidebar-level-3" class="mobile-sidebar" aria-labelledby="mobile-sidebar-title" no-header shadow>
      <template #default="{ hide }">
        <div class="p-0">

          <div class="mobile-header">
            <b-navbar-brand v-bind:to="'/'">
              <img :src="header.brand_image_url" alt="" class="footer-logo" />
            </b-navbar-brand>

            <b-navbar-toggle target="" @click.native="toggleSidebar($event)">
              <font-awesome-icon icon="fa-solid fa-bars" />
            </b-navbar-toggle>
          </div>

          <div class="px-2 my-3">
          <button class="btn btn-warning btn-back-sidebar" @click="hide">
              <font-awesome-icon icon="fa-solid fa-chevron-left" style="color: #fff;"/>  
          </button>
            <nav class="">
              <b-nav vertical class="nav-mobile">
                <li 
                  v-for="link in levelThreeLinks" 
                  :key="link.id"   
                  class="nav-item"
                >
				<a v-if="link.link_type == 'url'" :href="link.url" :target="link.target" class="nav-link">{{ link.name }}</a>
                  <router-link v-else
                    :to="link.level_four_links.length > 0 ? '' : link.url" 
                    @click.native="subMenuLevel4($event, link)"
                    class="nav-link" 
                   >
                    <span>{{ link.name }}</span>
                    <font-awesome-icon icon="fa-solid fa-chevron-right" class="nav-icon ml-2" style="stroke:#CF8C29; stroke-width: 35px; padding-top: 1px;" v-if="link.level_four_links.length > 0"/>  
                  </router-link>
                </li>
              </b-nav>
            </nav>  
          </div>
          
        </div>
      </template>
    </b-sidebar>

    <b-sidebar ref="mobileSidebar4" id="mobile-sidebar-level-4" class="mobile-sidebar" aria-labelledby="mobile-sidebar-title" no-header shadow>
      <template #default="{ hide }">
        <div class="p-0">

          <div class="mobile-header">
            <b-navbar-brand v-bind:to="'/'">
              <img :src="header.brand_image_url" alt="" class="footer-logo" />
            </b-navbar-brand>

            <b-navbar-toggle target="" @click.native="toggleSidebar($event)">
              <font-awesome-icon icon="fa-solid fa-bars" />
            </b-navbar-toggle>
          </div>

          <div class="px-2 my-3">
          <button class="btn btn-warning btn-back-sidebar" @click="hide">
              <font-awesome-icon icon="fa-solid fa-chevron-left" style="color: #fff;"/>  
          </button>
            <nav class="">
              <b-nav vertical class="nav-mobile">
                <li 
                  v-for="link in levelFourLinks" 
                  :key="link.id"   
                  class="nav-item"
                >
				<a v-if="link.link_type == 'url'" :href="link.url" :target="link.target" class="nav-link">{{ link.name }}</a>
                  <router-link v-else
                    :to="link.url"
                    class="nav-link" 
                  >
                    <span>{{ link.name }}</span>
                  </router-link>
                </li>
              </b-nav>
            </nav>  
          </div>
          
        </div>
      </template>
    </b-sidebar>

  </div>   
</template>

<script>
export default {
  name: 'Navbar',  
  props: {
    header: Object
  },
  data () {
    return {
      levelTwoLinks: {},
      levelThreeLinks: {},
      levelFourLinks: {},
      sidebar: false,
    }
  },
  mounted () {
    this.dropdownToggle();
  },
  updated() {
    this.dropdownToggle();
  },
  methods: {
    dropdownToggle() {
      $( document ).ready(function() {
        $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
          if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
          }
          var $subMenu = $(this).next('.dropdown-menu');
          $subMenu.toggleClass('show');

          $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
            $('.dropdown-submenu .show').removeClass('show');
          });

          return false;
        });
      });
    },
    toggleSidebar: function(e) {
      // e.preventDefault()
      if (!this.sidebar) {
        this.sidebar = true;
        this.$root.$emit('bv::toggle::collapse', 'mobile-sidebar')
      } else {
        this.$refs.mobileSidebar.hide();
        this.$refs.mobileSidebar2.hide();
        this.$refs.mobileSidebar3.hide();
        this.$refs.mobileSidebar4.hide();
        this.sidebar = false;
      }
    },
    hideSidebars() {
      this.$refs.mobileSidebar.hide();
      this.$refs.mobileSidebar2.hide();
      this.$refs.mobileSidebar3.hide();
      this.$refs.mobileSidebar4.hide();
    },
    subMenuLevel2: function(e, link) {
      if (link.level_two_links.length > 0) {
        e.preventDefault()
        // this.$refs['mobile-sidebar-level-2'].show()
        this.$root.$emit('bv::toggle::collapse', 'mobile-sidebar-level-2')

        this.levelTwoLinks = {};
        this.levelTwoLinks = link.level_two_links;
      } else {
        this.hideSidebars();
      }
      console.log(link)
    },
    subMenuLevel3: function(e, link) {
      if (link.level_three_links.length > 0) {
        e.preventDefault()
        this.$root.$emit('bv::toggle::collapse', 'mobile-sidebar-level-3')

        this.levelThreeLinks = {};
        this.levelThreeLinks = link.level_three_links;
      } else {
        this.hideSidebars();
      }
      console.log(link)
    },
    subMenuLevel4: function(e, link) {
    if (link.level_four_links.length > 0) {
      e.preventDefault()
      this.$root.$emit('bv::toggle::collapse', 'mobile-sidebar-level-4')

      this.levelFourLinks = {};
      this.levelFourLinks = link.level_four_links;
    } else {
        this.hideSidebars();
    }
    console.log(link)
  },
  }
}
</script>


<style>
  .navbar {
    padding: 33px 50px 12px 50px;
  }

  .navbar-brand {
    padding-bottom: 1rem;
  }

  .navbar-brand img {
    height: 75px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
  }

  .navbar-collapse {
    align-self: flex-end;
  }

  .navbar .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
    color: #e8a713;
    font-weight: 600;
  }

  .navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: #e8a713 !important;
  } 

  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #e8a713 !important;
  }  

  .navbar .navbar-nav .nav-link:hover span {
    border-bottom: 2px solid #e8a713 !important;
  }

  .dropdown-submenu .dropdown-item:hover, .dropdown-submenu .dropdown-item:focus {
    color: #e8a713 !important;
    background-color: #fff;
  }


  .dropdown-lang .dropdown-toggle::after {
    content: none !important;
  }

  .dropdown-menu {
    border-radius: 0px;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: .8em;
  }

  .dropdown-submenu .dropdown-menu {
    /* top: 0;
    left: 100%;
    margin-left: .1rem;
    margin-right: .1rem; */
    top: -11px;
    left: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .dropdown-item {
    color: #e8a713;
    font-weight: 600;    
  }

  @media only screen and (max-width: 768px) {
    .navbar-brand {
      padding-bottom: 0.3125rem;
    }

    .navbar-brand img {
      height: 45px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
    }
  }

  #mobile-sidebar, #mobile-sidebar-level-2, #mobile-sidebar-level-3, #mobile-sidebar-level-4 {
      background: #fff;
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100% !important;
      max-width: 100%;
      height: 100vh;
      max-height: 100%;
      margin: 0;
      outline: 0;
      transform: translateX(0);
      box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
  }

  .nav-mobile .nav-item a {
    color: #CF8C29;
    font-weight: 600; 
    font-size: 1.11em;
    padding: 0.57rem 0.5em 0.57rem 0.5rem;
    /* display: flex;
    align-items: center; */
  }

  .nav-mobile .nav-icon {
    margin-left: 0.32em;
    font-size: .7em;
  }

  .mobile-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 10px 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .mobile-header .navbar-toggler {
    border: none;
    color: #e8a713;
    font-size: 2.55em;
  }

  .mobile-header .navbar-toggler svg path {
    stroke: #e8a713;
    stroke-width: 0.5px;
  }

  .btn-back-sidebar {
    background: #e8a713;
    color: #fff;
    border-radius: 5px !important;
    font-size: 1rem;
    margin-left: 6px;
    margin-bottom: 20px;
  }
    
</style>